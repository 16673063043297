import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.js';
import "./App.css";


ReactDOM.render(
    <App />
  ,document.getElementById('root')
);
